import axios from "axios";

const baseUrl = process.env.VUE_APP_CSAPI_API_URL;
const headers = {
  "x-app-type": "cs-gifnoc"
};

const send = method => (action, data) =>
  axios({
    method,
    url: baseUrl + action,
    headers,
    data,
    params: method === "get" ? data : {}
  });

export default {
  addHeader: (key, value) => {
    headers[key] = value;
  },
  removeHeader: key => {
    delete headers[key];
  },
  get: (action, data) => send("get")(action, data),
  put: (action, data) => send("put")(action, data),
  post: (action, data) => send("post")(action, data),
  patch: (action, data) => send("patch")(action, data),
  delete: (action, data) => send("delete")(action, data)
};
