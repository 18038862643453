import Vue from 'vue';
import Router from 'vue-router';
import store from './store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import('./views/Login.vue'),
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('./views/Logout.vue'),
    },
    {
      path: '/dashboard',
      name: 'versoes',
      component: () => import('./views/controlsoft/Versoes.vue'),
    },
    {
      path: '/notificacoes',
      name: 'notificacoes',
      component: () => import('./views/Notificacoes.vue'),
    },
    {
      path: '/versoes/detalhes/:versao',
      name: 'versoesdetalhes',
      props: (route) => ({ id: route.query.versaodesc }),
      component: () => import('./views/controlsoft/VersoesDetalhes.vue'),
    },
    {
      path: '/clientes',
      name: 'clientes',
      component: () => import('./views/controlsoft/Clientes.vue'),
    },
    {
      path: '/clientes/detalhes/:cliente',
      name: 'clientesdetalhes',
      component: () => import('./views/controlsoft/ClientesDetalhes.vue'),
    },
    {
      path: '/consulta/produtoswebmobile',
      name: 'Consulta',
      component: () => import('./views/controlsoft/ConsultaProdutosWeb.vue'),
    },
    {
      path: '/erros',
      name: 'erros',
      component: () => import('./views/controlsoft/Erros.vue'),
    },
    {
      path: '/auditoria',
      name: 'Auditoria',
      component: () => import('./views/controlsoft/Auditoria.vue'),
    },
    {
      path: '/autenticacao-monitor',
      name: 'AutenticacaoMonitor',
      component: () => import('./views/controlsoft/Autenticacao_monitor.vue'),
    },
    {
      path: '/erros/detalhes/:hash',
      name: 'errosdetalhes',
      component: () => import('./views/controlsoft/ErrosDetalhes.vue'),
    },
    {
      path: '/atualizacao/cadastro',
      name: 'atualizacaoCadastro',
      component: () => import('./views/controlsoft/AtualizacaoCadastro.vue'),
    },
    {
      path: '/atualizacao/agenda',
      name: 'atualizacaoAgenda',
      component: () => import('./views/controlsoft/AtualizacaoAgenda.vue'),
    },
    {
      path: '/server/logs',
      name: 'ServerLogs',
      component: () => import('./views/controlsoft/server/Logs.vue'),
    },
    {
      path: '/server/status',
      name: 'ServerStatus',
      component: () => import('./views/controlsoft/server/Status.vue'),
    },
    {
      path: '/autenticacao',
      name: 'Autenticacao',
      component: () => import('./views/controlsoft/Autenticacao.vue'),
    },
    {
      path: '/agronotificacao',
      name: 'AgroNotificacao',
      component: () => import('./views/controlsoft/AgroNotificacao.vue'),
    },
    {
      path: '/agronotificacao/cadastro/:id',
      name: 'AgroNotificacaoCadastro',
      component: () => import('./views/controlsoft/AgroNotificacaoCadastro.vue'),
    },
    {
      path: '/agronotificacao/visualizacao/:id',
      name: 'AgroNotificacaoVisualizacao',
      component: () => import('./views/controlsoft/AgroNotificacaoCadastro.vue'),
    },
    // DataHub
    {
      path: '/datahub/',
      name: 'DatahubModelo',
      component: () => import('./views/controlsoft/datahub/Browse.vue'),
    },
    {
      path: '/datahub/cadastro/:id',
      name: 'DatahubModeloCadastro',
      component: () => import('./views/controlsoft/datahub/Cadastro.vue'),
    },
    {
      path: '/datahub/visualizacao/:id',
      name: 'DatahubModeloVisualizacao',
      component: () => import('./views/controlsoft/datahub/Cadastro.vue'),
    },
    {
      path: '/datahub/:id/views',
      name: 'DatahubRelatorios',
      component: () => import('./views/controlsoft/datahub/Resultados.vue'),
    },
    // Acompanhamento de Safra - Romaneios
    {
      path: '/romaneios',
      name: 'Romaneios',
      component: () => import('./views/controlsoft/Romaneios.vue'),
    },
    // DataBridge
    {
      path: '/databrigde/instituicoes-financeiras',
      name: 'InstituicoesFinanceiras',
      component: () => import('./views/controlsoft/databrigde/instituicoesFinanceiras.vue'),
    },
    {
      path: '/databrigde/reinf',
      name: 'Reinf',
      component: () => import('./views/controlsoft/databrigde/reinf.vue'),
    },
    {
      path: '/databrigde/responsavel-tecnico',
      name: 'ResponsavelTecnico',
      component: () => import('./views/controlsoft/databrigde/responsavelTecnico.vue'),
    },
    // CS API
    {
      path: '/csapi/admin-instrument',
      name: 'AdminInstrument',
      component: () => import('./views/controlsoft/csapi/adminInstrument.vue'),
    },
    // SySafra
    {
      path: '/sysafra/sysbackup/monitor',
      name: 'SysBackupDashboard',
      component: () => import('./views/sysafra/sysbackup/Monitor.vue'),
    },
    {
      path: '/sysafra/sysbackup/solicitacoes',
      name: 'SysBackupSolicitacoes',
      component: () => import('./views/sysafra/sysbackup/SolicitacaoBackup.vue'),
    },
    {
      path: '/sysafra/sysbackup/validacoes',
      name: 'SysBackupValidacoes',
      component: () => import('./views/sysafra/sysbackup/ValidacoesBackup.vue'),
    },
    {
      path: '/sysafra/sysbackup/clientes',
      name: 'SysBackupClientes',
      component: () => import('./views/sysafra/sysbackup/clientes/Index.vue'),
    },
    {
      path: '/sysafra/sysbackup/clientes/:cliente/historico-backups',
      name: 'SysBackupHistoricoBackups',
      component: () => import('./views/sysafra/sysbackup/clientes/HistoricoBackups.vue'),
    },
    {
      path: '/sysafra/sysbackup/config',
      name: 'SysBackupConfig',
      component: () => import('./views/sysafra/sysbackup/Config.vue'),
    },
    // Out of box
    {
      path: '/releases',
      name: 'releases',
      component: () => import('./views/controlsoft/releases.vue'),
    },
    {
      path: '/releases/:id',
      name: 'releasesVersao',
      component: () => import('./views/controlsoft/releasesVersao.vue'),
    },
  ],
});

router.beforeEach((to, from, next) => {
  const { user } = store.state.auth;
  const token = store.state.auth.auth;

  if (to.name === 'releases' || to.name === 'releasesVersao') {
    next();
  } else if (to.name !== 'logout' && to.name !== 'login' && (!user || !token)) {
    next({ name: 'login' });
  } else {
    next();
  }
});

export default router;
