<template lang="pug">
div
  v-card(outlined :class="cardClass" @click="dialog = true")
    v-card-text(class="pa-2")
      v-row(no-gutters align="center" justify="space-around")
        v-col(cols="4" class="text-start")
          .d-flex.flex-column.align-start.mt-4
          v-icon(left large) {{ icon }}
          h5.mb-0 {{ title }}

        v-col(cols="6" class="text-center")
          .d-flex.flex-column.align-end.px-4
            h1.mb-0.mt-0.font-weight-bold {{ mainValue }}
            span.mt-n1 {{ mainLabel }}

      v-divider(class="my-2")

      v-row(no-gutters align="center" justify="space-around")
        v-col(v-for="(stat, index) in stats" :key="index" cols="4" class="text-center")
          .d-flex.flex-column.align-end.px-2
            h3.mb-0 {{ stat.value }}
            span.mt-n1 {{ stat.label }}
  v-dialog(v-model="dialog" max-width="1000px" width="1000px")
    div
      v-card-title.headline.white--text(:style="{ backgroundColor: cardColor }")
        span {{ title }} - Detalhamento
        v-spacer
        v-btn(icon dark @click="dialog = false")
          v-icon mdi-close
      v-data-table(
        :headers="headers"
        :items="tableData"
        :items-per-page="10"
        no-data-text="Nenhum dado encontrado"
        :footer-props={
          showFirstLastPage: true,
          itemsPerPageText: 'Itens por página',
          itemsPerPageAllText: 'Todos',
          pageText: '{0}({1}) de {2}',
        }
      )
        template(v-slot:item.cargas="{ item }")
          span {{ formatNumber(item.cargas) }}
        template(v-slot:item.toneladas="{ item }")
          span {{ formatNumber(item.toneladas) }}
        template(v-slot:item.sacas="{ item }")
          span {{ formatNumber(item.sacas) }}
        template(v-slot:item.percentual="{ item }")
          span {{ formatPercent(item.percentual) }}
        
</template>

<script>
export default {
  name: "DashboardCard",
  data() {
    return {
      dialog: false,
      headers: [
        { text: "Descrição", value: "descricao", align: "start", width: "52%" },
        { text: "Cargas", value: "cargas", align: "end", width: "12%" },
        { text: "Toneladas", value: "toneladas", align: "end", width: "12%" },
        { text: "Sacas", value: "sacas", align: "end", width: "12%" },
        { text: "Percentual", value: "percentual", align: "end", width: "12%" }
      ]
    };
  },
  props: {
    color: {
      type: String,
      default: "green"
    },
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    mainValue: {
      type: [String, Number],
      required: true
    },
    mainLabel: {
      type: String,
      required: true
    },
    stats: {
      type: Array,
      required: true,
      validator: value => value.every(stat => "value" in stat && "label" in stat)
    },
    tableData: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    cardClass() {
      return {
        [`${this.color}-card`]: true
      };
    },

    cardColor() {
      const colors = {
        green: "#4caf50",
        blue: "#2196f3",
        orange: "#ff9800"
      };
      return colors[this.color] || colors.green;
    }
  },

  methods: {
    formatNumber(value) {
      if (value === null || value === undefined || isNaN(value)) {
        return "-";
      }
      return new Intl.NumberFormat("pt-BR").format(value);
    },

    formatPercent(value) {
      if (value === null || value === undefined || isNaN(value)) {
        return "-";
      }
      const formatted = new Intl.NumberFormat("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(value);
      return `${formatted}%`;
    }
  }
};
</script>

<style scoped>
.v-card.green-card {
  background-color: #4caf50;
}

.v-card.blue-card {
  background-color: #2196f3;
}

.v-card.orange-card {
  background-color: #ff9800;
}

.green-card,
.blue-card,
.orange-card,
[class*="-card"] .v-card__text,
[class*="-card"] h1,
[class*="-card"] h3,
[class*="-card"] span,
[class*="-card"] .v-icon {
  color: white !important;
}

[class*="-card"] .v-divider {
  border-color: rgba(255, 255, 255, 0.3) !important;
}

[class*="-card"] {
  cursor: pointer;
  transition: transform 0.2s, filter 0.2s;
}

[class*="-card"]:hover {
  transform: translateY(-2px);
  filter: brightness(95%);
}

[class*="-card"]:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
}

.headline {
  font-size: 18px !important;
  font-weight: 500;
  height: 50px;
  padding: 0;
  margin: 0;
  padding-left: 1.5%;
  border-bottom: solid 3px rgba(255, 255, 255, 0.5);
}

.v-data-table /deep/ {
  border-radius: 0%;
}

.v-data-table /deep/ .v-data-table__wrapper table thead {
  background-color: #f6f6f6;
}

.v-data-table /deep/ .v-data-table__wrapper table thead th span {
  font-size: 14px !important;
  font-weight: 500;
  color: black;
}

.v-data-table /deep/ tbody tr:nth-child(odd) {
  background-color: white;
}

.v-data-table /deep/ tbody tr:nth-child(odd) td {
  color: black;
}

.v-data-table /deep/ tbody tr:nth-child(even) {
  background-color: #f6f6f6;
}

.v-data-table /deep/ tbody tr:nth-child(even) td {
  color: black;
}

.v-data-table /deep/ tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.v-dialog [class*="-card"] {
  cursor: default;
  transform: none;
  filter: none;
  transition: none;
}

.v-dialog [class*="-card"]:hover {
  transform: none;
  filter: none;
  box-shadow: none;
}
</style>
