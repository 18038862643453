<template lang="pug">
v-card.pb-2(outlined)
  v-card-title(style="color: #00000099; font-size: 14px; font-weight: 500; height: 44px;") {{ cardTitle }}
    v-avatar(
      :color="idColor"
      class="ml-2"
      size="12"
    )
  v-card-text
    v-container
      v-row(v-for="(item, index) in percentageItens" :key="index" align="center" dense)
        v-col.d-flex.justify-start.mr-5(cols="2" class="text-body-1")
          span {{ item.label }}
        v-col.pr-2(cols="8" class="progress-col")
          v-progress-linear(:value="item.percentage" :color="item.color" height="5" rounded)
        v-col.d-flex.justify-end.ml-2(cols="1" class="text-body-2")
          span {{ item.value }}°
</template>

<script>
export default {
  name: "ProgressCard",
  props: {
    cardTitle: {
      type: String,
      default: "Progress Card"
    },
    idColor: {
      type: String,
      default: "green"
    },
    dataset: {
      type: Array,
      default: () => [
        { label: "Umidade", value: 0, color: "light-blue" },
        { label: "Impurezas", value: 0, color: "amber" },
        { label: "Ardidos", value: 0, color: "orange" },
        { label: "Avariados", value: 0, color: "red" },
        { label: "Esverdiados", value: 0, color: "green" },
        { label: "Quebrados", value: 0, color: "purple" }
      ],
      validator: value => value.every(item => "label" in item && "value" in item && "color" in item)
    }
  },

  computed: {
    percentageItens() {
      const maxValue = Math.max(...this.dataset.map(item => item.value));
      return this.dataset.map(item => ({
        ...item,
        percentage: (item.value / maxValue) * 100
      }));
    }
  }
};
</script>

<style scoped>
.progress-col {
  display: flex;
  align-items: center;
}

.v-progress-linear {
  margin: 0;
  width: 100%;
}

.text-body-1 {
  font-size: 13px !important;
  font-weight: 500;
}

.text-body-2 {
  font-size: 13px !important;
  font-weight: 500;
}

.title {
  height: 42px;
}

.v-card__text {
  padding-bottom: 0;
}
</style>
